<template>
  <div>
    <incident-history-form
      v-if="isEditing"
      :incident-history="incidentHistory"
      @submit="editIncidentHistory"
      :allow-cancel="true"
      @cancel="isEditing = false"
    />
    <v-card v-else class="elevation-2">
      <v-card-title>
        {{ $utils.timestampToHumanFormat(incidentHistory.time_created) }}
        <v-spacer />

        <v-icon>
          {{ $enums.privacyOpts.getObj(incidentHistory.is_public).icon }}
        </v-icon>

        <v-btn small @click="isEditing = true" v-can="$perms.incidents.edit">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          small
          @click="deleteIncidentHistory"
          v-can="$perms.incidents.edit"
        >
          <v-icon color="red" small>mdi-delete</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <span>{{ incidentHistory.description }}</span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { actions } from '@/store/incidents'
import IncidentHistoryForm from './IncidentHistoryForm.vue'

export default {
  components: { IncidentHistoryForm },
  props: {
    incidentId: Number,
    incidentHistory: Object,
  },
  data: () => ({
    isEditing: false,
  }),
  methods: {
    async editIncidentHistory(incidentHistory) {
      this.isEditing = false
      try {
        await this.$store.dispatch(actions.EDIT_INCIDENT_HISTORY, {
          incidentId: this.incidentId,
          incidentHistoryId: this.incidentHistory.id,
          incidentHistory,
        })
      } catch (error) {
        console.log(error)
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    async deleteIncidentHistory() {
      try {
        await this.$store.dispatch(actions.DELETE_INCIDENT_HISTORY, {
          incidentId: this.incidentId,
          incidentHistoryId: this.incidentHistory.id,
        })
      } catch (error) {
        console.log(error)
        this.$ui.showSnackbar(error.showSnackbar)
      }
    },
  },
}
</script>
