<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ alert.title }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            Created: {{ $utils.timestampToHumanFormat(alert.time_created) }}
          </v-col>
          <v-col cols="12" v-if="alert.time_resolved">
            Resolved: {{ $utils.timestampToHumanFormat(alert.time_resolved) }}
          </v-col>
          <v-col cols="12">
            <v-textarea
              filled
              readonly
              :value="alert.message"
              prepend-icon="mdi-comment"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <label-combobox :alert="alert" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!alert.time_resolved"
        color="blue darken-1"
        text
        @click="resolveAlert"
      >
        Resolve
      </v-btn>
      <v-btn v-else color="blue darken-1" text @click="reopenAlert">
        Reopen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { actions } from '@/store/alerts'
import LabelCombobox from './LabelCombobox.vue'

export default {
  components: {
    LabelCombobox,
  },
  props: {
    alert: Object,
  },
  data: () => ({
    isEditing: false,
  }),
  methods: {
    async resolveAlert() {
      try {
        await this.$store.dispatch(actions.RESOLVE_ALERTS, [this.alert.id])
        this.$ui.showSnackbar({
          text: `Successfully resolved 1 alert`,
          color: 'success',
        })
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      } finally {
        this.$emit('close-dialog')
      }
    },
    async reopenAlert() {
      try {
        await this.$store.dispatch(actions.REOPEN_ALERTS, [this.alert.id])
        this.$ui.showSnackbar({
          text: `Successfully reopened 1 alert`,
          color: 'success',
        })
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      } finally {
        this.$emit('close-dialog')
      }
    },
  },
}
</script>
