// should be synced with backend permissions (accessible via /auth/permission/ on the admin site)
export const permissions = {
  alerts: {
    view: 'view_alert',
    add: 'add_alert',
    edit: 'change_alert',
    delete: 'delete_alert',
  },
  incidents: {
    view: 'view_incident',
    add: 'add_incident',
    edit: 'change_incident',
    delete: 'delete_incident',
  },
  labels: {
    view: 'view_label',
    add: 'add_label',
    edit: 'change_label',
    delete: 'delete_label',
  },
  services: {
    view: 'view_service',
    add: 'add_service',
    edit: 'change_service',
    delete: 'delete_service',
  },
  indicators: {
    view: 'view_indicator',
  },
  gameconfigs: {
    view: 'view_gameconfig',
  }
}

// key to store permissions in localStorage. allows for persistence.
export const PERMISSIONS_KEY = 'permissions'
