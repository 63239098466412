import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Alerts from '@/views/Alerts'
import Incidents from '@/views/Incidents'
import AlertDetail from '@/views/AlertDetail'
import IncidentDetail from '@/views/IncidentDetail'
import NewIncident from '@/views/NewIncident'
import EditIncident from '@/views/EditIncident'
import UnauthorizedAccess from '@/views/UnauthorizedAccess'
import Indicators from '@/views/Indicators'
import GameConfig from '@/views/GameConfig'
import store from '@/store'

Vue.use(VueRouter)

export const routerUrlNames = {
  login: 'Login',
  alerts: 'Alerts',
  alertDetail: 'Alert Detail',
  incidents: 'Incidents',
  incidentDetail: 'Incident Detail',
  newIncident: 'New Incident',
  editIncident: 'Edit Incident',
  unauthorizedAccess: 'Unauthorized Access',
  indicators: 'Indicators Status',
  gameConfig: 'Game Config'
}

const routes = [
  {
    path: '',
    redirect: { name: routerUrlNames.incidents },
  },
  {
    path: '/login',
    name: routerUrlNames.login,
    component: Login,
  },
  {
    path: '/indicators',
    name: routerUrlNames.indicators,
    component: Indicators,
  },
  {
    path: '/alerts',
    name: routerUrlNames.alerts,
    component: Alerts,
  },
  {
    path: '/alerts/:id',
    name: routerUrlNames.alertDetail,
    component: AlertDetail,
  },
  {
    path: '/incidents/create',
    name: routerUrlNames.newIncident,
    component: NewIncident,
  },
  {
    path: '/incidents',
    name: routerUrlNames.incidents,
    component: Incidents,
  },
  {
    path: '/incidents/:id/edit',
    name: routerUrlNames.editIncident,
    component: EditIncident,
  },
  {
    path: '/incidents/:id',
    name: routerUrlNames.incidentDetail,
    component: IncidentDetail,
  },
  {
    path: '/gameconfig',
    name: routerUrlNames.gameConfig,
    component: GameConfig,
  },
  {
    path: '/unauthorized-access',
    name: routerUrlNames.unauthorizedAccess,
    component: UnauthorizedAccess,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== routerUrlNames.login && !store.state.auth.isAuthenticated) {
    next({
      name: routerUrlNames.login,
      query: { redirect: to.fullPath },
    })
  }

  next()
})

export default router
