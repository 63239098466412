<template>
  <v-container class="ml-12">
    <v-row>
      <v-col>
        <div class="text-h6">{{ header }}</div>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.title"
            label="Incident Title"
            filled
            dense
            autofocus
            :rules="[
              (v) => (v && v.length >= 1) || 'Required',
              (v) =>
                (v && v.length >= 5) || 'Should be at least 6 characters long',
            ]"
          >
            <template #label>
              Incident Title<span class="red--text"><strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-n6">
        <v-col>
          <ckeditor
            :editor="editor"
            v-model="form.description"
            :config="editorConfig"
            placeholder="Type the content here!"
            :rules="[
              (v) => (v && v.length >= 1) || 'Required',
            ]"
          ></ckeditor>
        </v-col>
      </v-row>
      <v-row class="mt-8 pt-0">
        <v-col>
          <datetime-picker
            filled
            label="Time From"
            v-model="form.time_opened"
            :datetime="new Date()"
          >
          </datetime-picker>
        </v-col>
        <v-col>
          <v-slider
            v-model="form.severity"
            label="Severity"
            inverse-label
            step="1"
            min="1"
            max="5"
            thumb-label="always"
            ticks
            tick-size="1"
            :thumb-color="$enums.severity.getObj(form.severity).color"
            :color="$enums.severity.getObj(form.severity).color"
            thumb-size="46"
            class="pt-3"
          >
            <template v-slot:thumb-label="{ value }">
              {{ $enums.severity.getObj(value).text }}
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="form.services"
            :items="services"
            :search-input.sync="serviceSearch"
            @update:search-input="updateSearch"
            clearable
            @change="(e) => (serviceSearch = null)"
            item-text="name"
            item-vaue="id"
            outlined
            dense
            chips
            small-chips
            deletable-chips
            label="Affected Services"
            multiple
            cache-items
            return-object
            hide-no-data
            hide-selected
            auto-select-first
            :rules="[atleastOneService]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-for="service in form.services" :key="service.id" class="mt-n8">
        <v-col cols="2">
          <v-text-field :value="service.name" readonly dense></v-text-field
        ></v-col>
        <v-col cols="3">
          <v-select
            :items="$enums.serviceStatus.array"
            v-model="service.status"
            item-value="value"
            dense
          >
            <template #[`item`]="{ item }">
              <v-icon dark :color="item.color" class="mr-1">{{
                item.icon
              }}</v-icon>
              {{ item.text }}
            </template>
            <template #[`selection`]="{ item }">
              <v-icon dark :color="item.color" class="mr-1">{{
                item.icon
              }}</v-icon
              >{{ item.text }}
            </template>
          </v-select></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="1">
          <v-btn @click="submitForm" dark color="secondary">
            {{ btnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { actions } from '@/store/services'
import DatetimePicker from '@/components/DatetimePicker'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const defaultVals = () => ({
  title: null,
  description: null,
  time_opened: new Date(),
  severity: 1,
  services: [],
})

export default Vue.extend({
  name: 'IncidentForm',
  components: {
    DatetimePicker,
  },
  props: {
    incident: {
      type: Object,
      default: null,
    },
    btnText: String,
    header: String,
  },
  data: () => ({
    form: {},
    valid: true,
    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'blockQuote',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo',
      ],
    },
    serviceSearch: null,
  }),
  computed: mapState({
    services(state) {
      return [...state.serviceStore.services.data, ...this.form.services]
    },
  }),
  created() {
    this.form = this.incident
      ? {
          ...this.incident,
          time_opened: this.$utils.timestampToDate(this.incident.time_opened),
        }
      : defaultVals()
  },
  methods: {
    submitForm() {
      if (this.valid) {
      return this.$emit('submit', {
        ...this.form,
        time_opened: this.$utils.dateToTimestamp(this.form.time_opened),
      })} else {
        this.$ui.showSnackbar({
          text: 'Something wrong with the form. Check and try again.',
          color: 'error',
        })
      }
    },
    updateSearch(search) {
      this.serviceSearch = search
      if (search) this.retrieveServices(search)
    },
    retrieveServices(name) {
      const params = { name }
      this.$store.dispatch(actions.FETCH_SERVICES_AUTOCOMPLETE, params)
    },
    atleastOneService(services) {
      return (
        this.form.services.length != 0 ||
        'Atleast one service should be selected'
      )
    },
  },
})
</script>
