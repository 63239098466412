import api from '.'
import { CSRF_KEY, errorKeys } from '@/constants/api'
import router, { routerUrlNames } from '@/router'

export default () => {
  // set CSRF token to satisfy backend check
  api.interceptors.request.use((config) => {
    config.headers.common['X-CSRFToken'] = getCookie(CSRF_KEY)
    return config
  })

  // process errors
  api.interceptors.response.use(
    function (response) {
      const payload = response.data
      if (payload.errors) {
        const error = payload.errors.error
        switch (error) {
          case errorKeys.error_no_login.text:
            router.push({ name: routerUrlNames.login })
            break
          case errorKeys.error_unauthorized_access.text:
            router.push({ name: routerUrlNames.unauthorizedAccess })
            break
          default:
            break
        }
        const errorObj =
          errorKeys[payload.errors.error] || errorKeys.error_general
        console.log(payload.errors)
        return Promise.reject(errorObj)
      }
      return payload
    },
    function (error) {
      console.log(error)
      return Promise.reject(errorKeys.error_general)
    }
  )
}

function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}
