import api from '@/api'
import { urls } from '@/constants/api'
import { PERMISSIONS_KEY } from '../../constants/permissions'

export const mutations = {
  SET_AUTHENTICATED: 'set_authenticated',
  UNSET_AUTHENTICATED: 'unset_authenticated',
  SET_PERMISSIONS: 'set_permissions',
  UNSET_PERMISSIONS: 'unset_permissions',
}

export const actions = {
  LOGIN: 'login',
  GOOGLE_LOGIN: 'google_login',
  LOGOUT: 'logout',
}

export const LOGIN_SUCCESS = 'login_success'
export const LOGOUT_SUCCESS = 'logout_success'
export const AUTH_LOGIN = 'auth_login'
export const AUTH_LOGOUT = 'auth_logout'

export default {
  state: {
    permissions: JSON.parse(localStorage.getItem(PERMISSIONS_KEY)) || [],
    // initially assume user is authenticated if permissions are set. if backend denies request, interceptors will automatically force login
    isAuthenticated: !!localStorage.getItem(PERMISSIONS_KEY),
  },
  mutations: {
    [mutations.SET_AUTHENTICATED](state) {
      state.isAuthenticated = true
    },
    [mutations.UNSET_AUTHENTICATED](state) {
      state.isAuthenticated = false
    },
    [mutations.SET_PERMISSIONS](state, permissions) {
      state.permissions = permissions
    },
    [mutations.UNSET_PERMISSIONS](state) {
      state.permissions.splice(0)
    },
  },
  actions: {
    async [actions.LOGIN]({ commit }, { username, password }) {
      const payload = await api.post(urls.login, { username, password })
      const permissions = payload.data.permissions
      commit(mutations.SET_AUTHENTICATED)
      commit(mutations.SET_PERMISSIONS, permissions)
      localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions))
    },
    async [actions.GOOGLE_LOGIN]({ commit }, access_token) {
      const payload = await api.post(urls.googleLogin, { access_token })
      const permissions = payload.data.permissions
      commit(mutations.SET_AUTHENTICATED)
      commit(mutations.SET_PERMISSIONS, permissions)
      localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions))
    },
    [actions.LOGOUT]({ commit }) {
      api.get(urls.logout)
      commit(mutations.UNSET_AUTHENTICATED)
      commit(mutations.UNSET_PERMISSIONS)
      localStorage.removeItem(PERMISSIONS_KEY)
    },
  },
  getters: {
    currentUserPermissions: (state) => {
      return state.permissions
    },
    hasPerm: (state, getters) => (perm) => {
      return getters.currentUserPermissions.includes(perm)
    },
  },
}
