import Vue from 'vue'
import { permissions as Permissions } from '@/constants/permissions'

Permissions.install = function (Vue) {
  Vue.prototype.$perms = Permissions
}

Vue.use(Permissions)

export default Permissions
