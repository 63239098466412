export const mutations = {
  SET_SNACKBAR: 'set_snackbar',
  UNSET_SNACKBAR: 'unset_snackbar',
}

export default {
  state: () => ({
    snackbar: {
      text: '',
      color: '',
      timeout: '',
      queueNo: 0, // added to allow for notification scheduling in future
    },
  }),
  mutations: {
    [mutations.SET_SNACKBAR](state, snackbar) {
      state.snackbar = {
        ...snackbar,
        queueNo: state.snackbar.queueNo + 1,
      }
    },
  },
  actions: {},
  getters: {},
}
