<template>
  <v-navigation-drawer class="primary" :width="55" app>
    <v-divider></v-divider>

    <v-list flat nav class="py-1">
      <v-list-item-group color="white" mandatory>
        <template>
          <v-list-item
            v-for="(item, index) in items"
            dense
            router
            :to="item.route"
            :key="index"
            v-can="item.permission"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-list-item-content v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </v-list-item-icon></v-list-item-content
                >
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <v-list-item-group>
        <v-list-item>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item-content v-on="on" @click="djangoAdmin">
                <v-list-item-icon color="white">
                  <v-icon color="white">mdi-account-circle</v-icon>
                </v-list-item-icon>
              </v-list-item-content>
            </template>
            <span>Django Admin</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item-content v-on="on" @click="logout">
                <v-list-item-icon color="white">
                  <v-icon color="white">mdi-account-off</v-icon>
                </v-list-item-icon>
              </v-list-item-content>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-list-item>
      </v-list-item-group>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { actions } from '@/store/auth'

export default {
  name: 'Drawer',
  data() {
    return {
      items: [
        {
          tooltip: 'Home',
          icon: 'mdi-domain',
          route: {
            name: this.$routerUrls.incidents,
          },
          permission: this.$perms.incidents.view,
        },
        {
          tooltip: 'Incidents',
          icon: 'mdi-skull-crossbones-outline',
          route: {
            name: this.$routerUrls.incidents,
          },
          permission: this.$perms.incidents.view,
        },
        {
          tooltip: 'Alerts',
          icon: 'mdi-alert-circle-outline',
          route: {
            name: this.$routerUrls.alerts,
          },
          permission: this.$perms.alerts.view,
        },
        {
          tooltip: 'Indicators',
          icon: 'mdi-heart-cog-outline',
          route: {
            name: this.$routerUrls.indicators,
          },
          permission: this.$perms.indicators.view,
        },
        {
          tooltip: 'Game Configs',
          icon: 'mdi-code-json',
          route: {
            name: this.$routerUrls.gameConfig,
          },
          permission: this.$perms.gameconfigs.view,
        },
      ],
    }
  },
  methods: {
    djangoAdmin() {
      window.location.href = "/admin/"
    },
    logout() {
      this.$store.dispatch(actions.LOGOUT)
      this.$router.push({ name: this.$routerUrls.login })
    },
  },
}
</script>

<style scoped>
.v-list-item-group .v-list-item--active {
  background-color: #4caf50;
  color: white;
}
.v-list-item:hover {
  background: #68af4c;
}
</style>
