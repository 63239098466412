import { render, staticRenderFns } from "./LabelCombobox.vue?vue&type=template&id=436a449e&"
import script from "./LabelCombobox.vue?vue&type=script&lang=js&"
export * from "./LabelCombobox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCombobox,VIcon,VListItem,VListItemContent,VListItemTitle,VSlideXReverseTransition})
