export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL
export const CSRF_KEY = 'csrftoken'

export const urls = {
  csrf: 'csrf/',
  login: 'login/',
  googleLogin: 'login/google-oauth2/',
  logout: 'logout/',

  // Labels
  labels: 'labels/',
  labelsAutocomplete: 'labels/autocomplete/',
  editLabel: (id) => `labels/${id}/edit/`,
  deleteLabel: (id) => `labels/${id}/delete/`,

  // Alerts
  alerts: 'alerts/',
  alertsDetail: (id) => `alerts/${id}/`,
  resolveAlerts: `alerts/resolve/`,
  reopenAlerts: `alerts/reopen/`,
  resolveAlertsBulk: `alerts/resolve_bulk/`,
  reopenAlertsBulk: `alerts/reopen_bulk/`,

  // Services
  services: 'services/',
  servicesAutocomplete: 'services/autocomplete/',
  editService: (id) => `services/${id}/edit/`,
  deleteService: (id) => `services/${id}/delete/`,
  
  // Alert Labels
  alertsCreateLabel: (id) => `alerts/${id}/labels/create/`,
  alertsAddLabelAndCreate: (id) =>
    `alerts/${id}/labels/create_label_and_create/`,
  deleteAlertLabel: (id) => `alerts/${id}/labels/delete/`,

  // Incidents
  incidents: 'incidents/',
  createIncident: 'incidents/create/',
  incidentsDetail: (id) => `incidents/${id}/`,
  editIncident: (id) => `incidents/${id}/edit/`,
  deleteIncident: (id) => `incidents/${id}/delete/`,
  resolveIncident: (id) => `incidents/${id}/resolve/`,
  reopenIncident: (id) => `incidents/${id}/reopen/`,

  // Incident Updates
  incidentHistories: (id) => `incidents/${id}/histories/`,
  createIncidentHistory: (id) => `incidents/${id}/histories/create/`,
  editIncidentHistory: (id, incidentHistoryId) =>
    `incidents/${id}/histories/${incidentHistoryId}/edit/`,
  deleteIncidentHistory: (id, incidentHistoryId) =>
    `incidents/${id}/histories/${incidentHistoryId}/delete/`,

  // Indicator Status
  indicatorsIndex: `indicators/index/`,
  indicatorsLabels: `indicators/labels/`,
  indicatorsTabs: `indicators/tabs/`,
  indicatorsPage: `indicators/page/`,

  // Game Config
  gameConfigIndex: `gameconfig/index/`,
  gameConfigDetails: (id) => `gameconfig/${id}/`
}

// error key and text should be synced with libs/constants.py file in backend, except error_general
export const errorKeys = {
  error_general: {
    text: 'error_general',
    snackbar: {
      text: 'Something went wrong. Try again!',
      color: 'error',
    },
  },
  error_invalid_args: {
    text: 'error_invalid_args',
    snackbar: {
      text: 'Something went wrong. Try again!',
      color: 'error',
    },
  },
  error_no_login: {
    text: 'error_no_login',
    snackbar: {
      text: "You haven't logged in yet. Try logging in now.",
      color: 'error',
    },
  },
  error_invalid_login: {
    text: 'error_invalid_login',
    snackbar: {
      text: 'Login unsuccessful. Try again, or contact the administrator.',
      color: 'error',
    },
  },
  error_unauthorized_access: {
    text: 'error_unauthorized_access',
    snackbar: {
      text: 'You do not have permission to access this resource.',
      color: 'error',
    },
  },
  error_alert_not_found: {
    text: 'error_alert_not_found',
    snackbar: {
      text: 'Alert with this ID not found!',
      color: 'error',
    },
  },
  error_invalid_page: {
    text: 'error_invalid_page',
    snackbar: {
      text: 'This page does not exist!',
      color: 'error',
    },
  },
  error_invalid_severity: {
    text: 'error_invalid_severity',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_service_not_found: {
    text: 'error_service_not_found',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_label_not_found: {
    text: 'error_label_not_found',
    snackbar: {
      text: "Couldn't find a label with this ID.",
      color: 'error',
    },
  },
  error_incident_not_found: {
    text: 'error_incident_not_found',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_incident_history_not_found: {
    text: 'error_incident_history_not_found',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_alert_label_not_found: {
    text: 'error_alert_label_not_found',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_alert_label_exists: {
    text: 'error_alert_label_exists',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_incident_service_exists: {
    text: 'error_incident_service_exists',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_incident_service_not_found: {
    text: 'error_incident_service_not_found',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_incident_already_resolved: {
    text: 'error_incident_already_resolved',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_incident_not_resolved: {
    text: 'error_incident_not_resolved',
    snackbar: {
      text: 'Something went wrong. Check the form and submit again',
      color: 'error',
    },
  },
  error_alert_already_resolved: {
    text: 'error_alert_already_resolved',
    snackbar: {
      text: 'Some of the alert(s) you selected have already been resolved!',
      color: 'error',
    },
  },
  error_alert_not_resolved: {
    text: 'error_alert_not_resolved',
    snackbar: {
      text: "Some of the alert(s) you selected can't be reopened, since they haven't been resolved",
      color: 'error',
    },
  },
  error_label_already_associated: {
    text: 'error_label_already_associated',
    snackbar: {
      text: 'This label is already associated with some alerts. Remove it from the alerts, then try deleting.',
      color: 'error',
    },
  },
  error_service_already_affected: {
    text: 'error_service_already_affected',
    snackbar: {
      text: 'This service is already associated with another incident. Remove it from the incident first.',
      color: 'error',
    },
  },
  error_label_already_exists: {
    text: 'error_label_already_exists',
    snackbar: {
      text: 'A label with this name already exists!',
      color: 'error',
    },
  },
  error_service_already_exists: {
    text: 'error_service_already_exists',
    snackbar: {
      text: 'A service with this name already exists!',
      color: 'error',
    },
  },
  error_invalid_service_status: {
    text: 'error_invalid_service_status',
    snackbar: {
      text: 'One or more services has invalid status!',
      color: 'error',
    },
  },
}

export const errorStatusCodes = {
  FORBIDDEN_CSRF: 403,
  INTERNAL_SERVER_ERROR: 500,
}
