<template>
  <v-container class="ma-0">
    <v-card>
      <v-card-title
        >#{{ incident.id }}: {{ incident.title }}
        <v-service-chip
          v-for="service in incident.services"
          :service="service"
          :key="service.id"
          :class="'ml-2'"
        />
        <v-spacer />

        <v-btn
          v-if="incident.time_resolved"
          @click="reopenIncident"
          class="mr-2"
          small
          v-can="$perms.incidents.edit"
          >Reopen</v-btn
        >
        <v-btn
          v-else
          @click="resolveIncident"
          class="mr-2"
          small
          v-can="$perms.incidents.edit"
          >Resolve</v-btn
        >

        <v-btn
          class="mr-2"
          small
          @click="routeToEditIncident"
          v-can="$perms.incidents.edit"
        >
          <v-icon small round>mdi-pencil</v-icon>
        </v-btn>

        <v-dialog max-width="450">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on" v-can="$perms.incidents.edit"
              ><v-icon color="red" small round>mdi-delete</v-icon></v-btn
            >
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title>
                Do you really want to delete this incident?
              </v-card-title>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">No</v-btn>
                <v-btn text color="red" @click="deleteIncident(dialog)"
                  >Yes</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-card-title>
      <v-card-subtitle>
        <span>
          Started: {{ $utils.timestampToHumanFormat(incident.time_opened) }}
        </span>
        <span v-if="incident.time_resolved">
          ; Ended:
          {{ $utils.timestampToHumanFormat(incident.time_resolved) }}
        </span>
      </v-card-subtitle>
      <v-card-text v-html="incident.description" />
    </v-card>
    <incident-history-timeline
      :incident-id="incident.id"
      :is-resolved="incident.time_resolved !== undefined"
    />
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import LabelCombobox from '@/components/LabelCombobox'
import IncidentHistoryTimeline from '@/components/IncidentHistoryTimeline'
import VServiceChip from '@/components/VServiceChip'
import { actions } from '@/store/incidents'
import store from '@/store'

export default Vue.extend({
  name: 'IncidentDetail',
  components: {
    LabelCombobox,
    IncidentHistoryTimeline,
    VServiceChip,
  },
  computed: {
    ...mapState({
      incident: (state) => state.incidentStore.currentIncident,
      services: (state) => state.serviceStore.services.data,
    }),
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(actions.FETCH_INCIDENT_HISTORIES, to.params.id)
    store
      .dispatch(actions.FETCH_INCIDENT, to.params.id)
      .then(() => next((vm) => (vm.loading = false)))
  },
  methods: {
    updateSearch(search) {
      this.serviceSearch = search
      if (search) this.retrieveServices(search)
    },
    retrieveServices(name) {
      const params = { name }
      this.$store.dispatch(actions.FETCH_SERVICES_AUTOCOMPLETE, params)
    },
    async resolveIncident() {
      const id = this.incident.id
      try {
        await this.$store.dispatch(actions.RESOLVE_INCIDENT, id)
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    async reopenIncident() {
      const id = this.incident.id
      try {
        await this.$store.dispatch(actions.REOPEN_INCIDENT, id)
      } catch (error) {
        console.log(error)
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    routeToEditIncident() {
      this.$router.push({
        name: this.$routerUrls.editIncident,
        params: { id: this.incident.id },
      })
    },
    deleteIncident(dialog) {
      this.$store.dispatch(actions.DELETE_INCIDENT, this.incident.id)
      dialog.value = false
      this.$router.push({ name: this.$routerUrls.incidents })
    },
  },
})
</script>
