<template>
  <v-container class="ma-0 pa-0">
    <v-timeline dense clipped>
      <v-timeline-item
        fill-dot
        class="white--text"
        color="orange"
        large
        v-if="!isResolved"
      >
        <incident-history-form
          v-can="$perms.incidents.edit"
          @submit="createIncidentHistory"
        />
      </v-timeline-item>

      <v-slide-x-transition group>
        <v-timeline-item
          v-for="incidentHistory in incidentHistories"
          :key="incidentHistory.id"
          class="mb-4"
          :color="
            $enums.incidentStatus.getObj(incidentHistory.incident_status).color
          "
          small
        >
          <incident-history-card
            :incident-history="incidentHistory"
            :incident-id="incidentId"
          />
        </v-timeline-item>
      </v-slide-x-transition>
    </v-timeline>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { actions } from '@/store/incidents'
import IncidentHistoryForm from '@/components/IncidentHistoryForm'
import IncidentHistoryCard from '@/components/IncidentHistoryCard'

export default {
  components: {
    IncidentHistoryForm,
    IncidentHistoryCard,
  },
  props: {
    incidentId: Number,
    isResolved: Boolean,
  },
  computed: mapState({
    incidentHistories: (state) => state.incidentStore.currentIncidentHistories.data,
  }),
  methods: {
    async createIncidentHistory(incidentHistory) {
      try {
        await this.$store.dispatch(actions.CREATE_INCIDENT_HISTORY, {
          incidentId: this.incidentId,
          incidentHistory,
        })
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      }
    },
  },
}
</script>
