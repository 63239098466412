<template>
  <v-container class="ma-2" fluid>
    <v-expansion-panels class="condensed mb-4">
      <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3"
          >Advanced Search</v-expansion-panel-header
        >

        <v-expansion-panel-content>
          <v-row class="mt-4 mb-n6 ml-n4">
            <v-col cols="auto">
              <v-checkbox
                dense
                :false-value="$enums.boolean.enum.FALSE"
                :true-value="$enums.boolean.enum.TRUE"
                label="Open Only"
                v-model="open_only"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="labelsChosen"
                label="Include Labels"
                :items="labels"
                :search-input.sync="labelSearch"
                @update:search-input="updateSearch"
                clearable
                @change="(e) => (labelSearch = null)"
                item-text="name"
                item-vaue="id"
                dense
                outlined
                chips
                small-chips
                deletable-chips
                multiple
                return-object
                cache-items
                append-icon=""
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="labelsExclude"
                label="Exclude Labels"
                :items="labels"
                :search-input.sync="labelSearchExclude"
                @update:search-input="updateSearchExclude"
                clearable
                @change="(e) => (labelSearchExclude = null)"
                item-text="name"
                item-vaue="id"
                dense
                outlined
                chips
                small-chips
                deletable-chips
                multiple
                return-object
                cache-items
                append-icon=""
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <datetime-picker
                label="Time From"
                v-model="time_from"
                :datePickerProps='{max:new Date().toISOString().substr(0, 10)}'
                :disabled="
                  time_period != $enums.timePeriodFilter.enum.CUSTOM.value
                "
              >
              </datetime-picker>
            </v-col>
            <v-col cols="auto">
              <datetime-picker
                label="Time To"
                v-model="time_to"
                :datePickerProps='{max:new Date().toISOString().substr(0, 10)}'
                :disabled="
                  time_period != $enums.timePeriodFilter.enum.CUSTOM.value
                "
              >
              </datetime-picker>
            </v-col>
            <v-col cols="auto"> </v-col>
            <v-col>
              <v-btn small color="primary" fab @click="retrieveFilteredAlerts"
                ><v-icon dark>mdi-magnify</v-icon></v-btn
              >
              <v-btn class="ml-2" small fab @click="clearFilters"
                ><v-icon dark>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialog" max-width="600px">
      <alert-card :alert="clickedAlert" @close-dialog="dialog = false" />
    </v-dialog>

    <v-data-table
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :items="alerts"
      show-select
      v-model="selectedAlerts"
      class="elevation-1"
      :item-class="itemRowBackground"
      :footer-props="{
        'items-per-page-options': [10, 15, 20],
      }"
      @click:row="handleRowClick"
    >
      <template #[`body.prepend`]>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-text-field
              v-model="title"
              clearable
              dense
              @keydown.enter="retrieveFilteredAlerts"
              @click:clear="clearTitle"
            ></v-text-field>
          </td>
          <td>
            <v-select
              v-model="severity"
              :items="$enums.severity.array"
              item-text="text"
              item-value="value"
              menu-props="offset-y"
              clearable
              dense
            />
          </td>
          <td>
            <v-select
              v-model="time_period"
              dense
              :items="$enums.timePeriodFilter.array"
              menu-props="offset-y"
              clearable
            />
          </td>
          <td></td>
        </tr>
      </template>

      <template #[`header.data-table-select`]="{ props, on }">
        <v-simple-checkbox :value="props.value" v-on="on" color="primary" />
      </template>

      <template #[`item.data-table-select`]="{ isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>

      <template #[`header.actions`]>
        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense rounded>
            <v-list-item @click="resolveSelectedAlerts">
              <v-list-item-title>Resolve Selected</v-list-item-title>
            </v-list-item>
            <v-list-item @click="reopenSelectedAlerts">
              <v-list-item-title>Reopen Selected</v-list-item-title>
            </v-list-item>
            <v-list-item @click="warningResolve = true">
              <v-list-item-title>Resolve All Matching Filter</v-list-item-title>
            </v-list-item>
            <v-list-item @click="warningReopen = true">
              <v-list-item-title>Reopen All Matching Filter</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn icon @click.stop="openInNewTab(item)"
          ><v-icon small>mdi-open-in-new</v-icon></v-btn
        >
      </template>

      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <template #[`item.severity`]="{ item }">
        {{ $enums.severity.getObj(item.severity).text }}
      </template>

      <template #[`item.time_created`]="{ item }">
        {{ $utils.timestampToHumanFormat(item.time_created) }}
      </template>

      <template #[`item.labels`]="{ item }">
        <v-label-chip
          v-for="label in item.labels"
          :key="label.id"
          :label="label"
          :class="'ma-1'"
        />
      </template>
    </v-data-table>
    <v-dialog
      v-model="warningResolve"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 red darken-2 white--text">
          Warning: Resolve all matching filter
        </v-card-title>

        <v-card-text
          class="pt-4">
          This action will resolve all the alerts matching the search filter provided. 
        </v-card-text>
        <v-card-text
          class="pt-4 red--text font-weight-bold">
          Please check that you have a filter corresponding to the alerts you want to resolve. Proceed?
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="warningResolve = false; resolveAlertsBulk();"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warningReopen"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 red darken-2 white--text">
          Warning: Reopen all matching filter
        </v-card-title>

        <v-card-text
          class="pt-4">
          This action will reopen all the alerts matching the search filter provided.
        </v-card-text>
        <v-card-text
          class="pt-4 red--text font-weight-bold">
          Please check that you have a filter corresponding to the alerts you want to reopen. Proceed?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="warningReopen = false; reopenAlertsBulk();"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import VLabelChip from '@/components/VLabelChip'
import AlertCard from '@/components/AlertCard'
import DatetimePicker from '@/components/DatetimePicker.vue'
import { actions as labelActions } from '@/store/labels'
import { actions as alertActions } from '@/store/alerts'

export default Vue.extend({
  name: 'AlertsTable',
  components: {
    DatetimePicker,
    AlertCard,
    VLabelChip,
  },
  data() {
    return {
      selectedAlerts: [],
      dialog: false,
      clickedAlert: null,
      warningResolve: false,
      warningReopen: false,

      // form
      valid: false,

      // filters
      labelsChosen: [],
      labelsExclude: [],
      labelSearch: null,
      labelSearchExclude: null,
      title: null,
      open_only: this.$enums.boolean.enum.FALSE,
      time_period: null,
      time_from: null,
      time_to: null,
      severity: null,

      headers: [
        {
          sortable: false,
          value: 'data-table-select',
        },
        { sortable: false, value: 'actions', width: '1%' },
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          width: '1%',
          sortable: false,
        },
        {
          text: 'Title',
          value: 'title',
          width: '25%',
          fixed: true,
          sortable: false,
        },
        {
          text: 'Severity',
          value: 'severity',
          width: '10%',
          fixed: true,
          sortable: false,
        },
        {
          text: 'Time Created',
          value: 'time_created',
          width: '15%',
          fixed: true,
          sortable: false,
        },
        { text: 'Labels', sortable: false, value: 'labels', sortable: false },
      ],
      options: {
        page: 1,
        itemsPerPage: 15,
      },

      loading: false,
    }
  },
  computed: mapState({
    alerts: (state) => state.alertStore.alerts.data,
    totalItems: (state) => state.alertStore.alerts.meta.size,
    labels: (state) => state.labelStore.labels.data,
  }),
  watch: {
    options: {
      handler() {
        this.retrieveAlerts()
      },
      deep: true,
    },
    severity() {
      this.retrieveFilteredAlerts()
    },
    time_period(val) {
      if (val != this.$enums.timePeriodFilter.enum.CUSTOM.value)
        this.retrieveFilteredAlerts()
    },
  },
  methods: {
    retrieveAlerts(page) {
      this.loading = true
      const params = this.getRequestParams(page)
      this.$store
        .dispatch(alertActions.FETCH_ALERTS, params)
        .then(() => (this.loading = false))
    },
    retrieveFilteredAlerts() {
      this.retrieveAlerts(1) // set page to 1
    },
    clearFilters() {
      this.labelsChosen = []
      this.labelSearch = null
      this.labelsExclude = []
      this.labelSearchExclude = null
      this.time_from = null
      this.time_to = null
      this.time_period = null
      this.retrieveAlerts()
    },
    retrieveLabels(name) {
      const params = { name }
      this.$store.dispatch(labelActions.FETCH_LABELS_AUTOCOMPLETE, params)
    },
    async resolveSelectedAlerts() {
      try {
        await this.$store.dispatch(
          alertActions.RESOLVE_ALERTS,
          this.selectedAlerts.map((alert) => alert.id)
        )
        this.$ui.showSnackbar({
          text: `Successfully resolved ${this.selectedAlerts.length} alert(s)`,
          color: 'success',
        })
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    async reopenSelectedAlerts() {
      try {
        await this.$store.dispatch(
          alertActions.REOPEN_ALERTS,
          this.selectedAlerts.map((alert) => alert.id)
        )
        this.$ui.showSnackbar({
          text: `Successfully reopened ${this.selectedAlerts.length} alert(s)`,
          color: 'success',
        })
      } catch (error) {
        console.log(error)
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    async resolveAlertsBulk() {
      if (this.labelsChosen.length || this.labelsExclude.length || !!this.time_from || !!this.time_to || !!this.time_period) {
        const params = this.getRequestParams(1)
        try {
          await this.$store.dispatch(
            alertActions.RESOLVE_ALERTS_BULK,
            params
          )
          this.$ui.showSnackbar({
            text: `Successfully resolved all matching alert(s)`,
            color: 'success',
          })
        } catch (error) {
          this.$ui.showSnackbar(error.snackbar)
        }
      } else {
        this.$ui.showSnackbar({
          text: `No filter selected. Please select some filter for bulk resolve.`,
          color: 'error',
        })
      }
    },
    async reopenAlertsBulk() {
      if (this.labelsChosen.length || this.labelsExclude.length || !!this.time_from || !!this.time_to || !!this.time_period) {
        const params = this.getRequestParams(1)
        try {
          await this.$store.dispatch(
            alertActions.REOPEN_ALERTS_BULK,
            params
          )
          this.$ui.showSnackbar({
            text: `Successfully reopened all matching alert(s)`,
            color: 'success',
          })
        } catch (error) {
          console.log(error)
          this.$ui.showSnackbar(error.snackbar)
        }
      } else {
        this.$ui.showSnackbar({
          text: `No filter selected. Please select some filter for bulk reopen.`,
          color: 'error',
        })
      }
    },
    clearTitle() {
      this.title = null
      this.retrieveFilteredAlerts()
    },
    updateSearch(search) {
      this.labelSearch = search
      if (search) this.retrieveLabels(search)
    },
    updateSearchExclude(search) {
      this.labelSearchExclude = search
      if (search) this.retrieveLabels(search)
    },
    getRequestParams(page) {
      let params = {
        page: page || this.options.page,
        limit: this.options.itemsPerPage,
      }

      params.open_only = this.open_only

      if (this.severity) {
        params.severity = this.severity
      }

      if (this.title) {
        params.title = this.title
      }

      if (this.labelsChosen.length) {
        params.label_ids_include = this.$utils.serializeIdArray(this.labelsChosen)
      }

      if (this.labelsExclude.length) {
        params.label_ids_exclude = this.$utils.serializeIdArray(this.labelsExclude)
      }

      if (this.time_period) {
        params.time_period = this.time_period
      }

      if (this.time_from) {
        params.time_from = this.$utils.dateToTimestamp(this.time_from)
      }

      if (this.time_to) {
        params.time_to = this.$utils.dateToTimestamp(this.time_to)
      }

      return params
    },
    handleRowClick(item) {
      this.clickedAlert = item
      this.dialog = true
    },
    openInNewTab(item) {
      const href = this.$router.resolve({
        name: this.$routerUrls.alertDetail,
        params: { id: item.id },
      }).href
      window.open(href, '_blank')
    },
    itemRowBackground(item) {
      return 'pointer ' + (item.time_resolved ? 'row-resolved' : '')
    },
  },
})
</script>

<style>
.pointer {
  cursor: pointer;
}
.row-resolved {
  background-color: rgb(214, 245, 233);
}
.v-expansion-panels.condensed .v-expansion-panel-header {
  min-height: auto;
}
</style>
