<template>
  <v-card>
    <v-overlay
      :value="overlay"
    >
      <v-progress-circular indeterminate size="64">
      </v-progress-circular>
    </v-overlay>
    <v-tabs
      v-model="tab"
      align-with-title
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab
        v-for="t in tabs"
        :key="`${ t }`"
      >
        {{ t }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="t in tabs"
        :key="`${ t }`"
      >
        <v-container>
          <v-combobox
            v-model="selectedGameIdRegion"
            :items="gameIdRegions"
            label="Game ID / Regions"
            outlined
            dense
          ></v-combobox>
          <v-row>
            <v-col>
              <v-btn
                :loading="button_loading"
                :disabled="button_loading"
                color="blue"
                class="mr-2 white--text"
                @click="button_loading = true"
              >
                <v-icon dark>
                  mdi-reload
                </v-icon>
              </v-btn>  
              Last loaded: {{last_loaded[tabs[tab]] ? $utils.timestampToHumanFormat(last_loaded[tabs[tab]]) : "N/A" }}
            </v-col>
            <v-col cols="auto" align="right" class="mt-2 mr-2 font-weight-bold">
                Auto-refreshed every 5 minutes
            </v-col>
            
            <template v-for="(value, key, index) in items[t]">
              <v-col
                :key="index"
                class="mt-2"
                cols="12"
              >
                <strong>{{ key }}</strong>
              </v-col>

              <v-col
                v-for="(value2, key2, index2) in value"
                :key="`${ index2 + key2 + key }`"
                cols="4"
              >
                <v-card 
                  :color="value2 > 0.99 || value2 > 0.95 && key2.includes('Latency') ? '#52d172' : value2 > 0.9 ? '#f8c359' : value2 >= 0 ? '#ff7961' : '#999999'"
                  height="100%">
                  <v-card-text class="font-weight-bold pb-0 text-center">{{ key2 }}</v-card-text>
                  <v-card-text class="text-center pt-0 text-h3 font-weight-bold">{{ isNaN(value2) ? 'N/A' : ((value2 * 100).toFixed(4) + '%') }}</v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import { urls } from '@/constants/api'

export default Vue.extend({
  name: 'Indicators',
  components: {
  },
  watch: {
    button_loading () {
      this.overlay = true;
      this.get_page()
      clearInterval(this.interval_get_page)
      this.interval_get_page()
    },
    tab () {
      this.overlay = true;
      this.get_dropdown()
    },
    selectedGameIdRegion () {
      this.overlay = true;
      this.get_page()
      clearInterval(this.interval_get_page)
      this.interval_get_page()
    }
  },
  computed: {

  },
  methods: {
    get_tabs: async function(){
      try {
        const payload = await api.get(urls.indicatorsTabs);
        this.tabs = payload["data"];
        this.overlay = false;
        this.button_loading = false;
        for (const i = 0; i < tabs.length(); i++) {
          const key = this.tabs[i]
          const val = {}
          Object.assign(this.items, {key: val})
        }
      } catch (err) {
        console.log(err)
      }
    },
    get_dropdown: async function(){
      try {
        const dropdown = await api.get(urls.indicatorsLabels, {params: {"title": this.tabs[this.tab]}})
        this.gameIdRegions = dropdown["data"]
        this.overlay = false;
        this.button_loading = false;
      } catch (err) {
        console.log(err)
      }
    },
    get_page: async function(){
      try {
        if (this.gameIdRegions.includes(this.selectedGameIdRegion)){
          const endTime = Math.ceil(Date.now()/1000/30) * 30;
          const startTime = endTime - 300;
          const step = 30;
          const title = this.tabs[this.tab]
          const payload = await api.get(urls.indicatorsPage, {params :  {
              "start_time": startTime,
              "end_time": endTime,
              "step": step,
              "title": title,
              "label_val": this.selectedGameIdRegion
            }});
          Vue.set(this.items, title, payload["data"]);
          Vue.set(this.last_loaded, title, Math.floor(Date.now()/1000))
        }
        this.overlay = false;
        this.button_loading = false;
      } catch (err) {
        console.log(err)
      }
    },
    interval_get_page: function(){
      setInterval(this.get_page, 300000);
    }
  },
  mounted() {
    this.get_tabs()
  },
  data () {
    return {
      tab: null,
      tabs: [],
      gameids: [],
      regions: [],
      items: {},
      overlay: true,
      button_loading: false,
      last_loaded: {},
      selectedGameIdRegion: "",
      gameIdRegions:[]
    }
  },
  beforeDestroy () {
    clearInterval(this.interval_get_page)
  }
})
</script>
