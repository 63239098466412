import api from '@/api'
import { urls } from '@/constants/api'

const mutations = {
  SET_LABELS: 'set_labels',
  EDIT_LABEL: 'edit_label',
  REMOVE_LABEL: 'remove_label',
}

export const actions = {
  FETCH_LABELS: 'fetch_labels',
  FETCH_LABELS_AUTOCOMPLETE: 'fetch_labels_autocomplete',
  EDIT_LABEL: 'edit_label',
  DELETE_LABEL: 'delete_label',
}

export default {
  state: {
    labels: {
      meta: {},
      data: [],
    },
  },
  mutations: {
    [mutations.SET_LABELS](state, labels) {
      state.labels = labels
    },
    [mutations.EDIT_LABEL](state, { labelId, name }) {
      const label = state.labels.data.find((label) => label.id === labelId)
      Object.assign(label, { name })
    },
    [mutations.REMOVE_LABEL](state, labelId) {
      state.labels.data = state.labels.data.filter((l) => l.id !== labelId)
    },
  },
  actions: {
    async [actions.FETCH_LABELS]({ commit }) {
      const payload = await api.get(urls.labels)
      commit(mutations.SET_LABELS, payload)
    },
    async [actions.FETCH_LABELS_AUTOCOMPLETE]({ commit }, params) {
      const payload = await api.get(urls.labelsAutocomplete, { params })
      commit(mutations.SET_LABELS, payload)
    },
    async [actions.EDIT_LABEL]({ commit }, { labelId, name }) {
      await api.post(urls.editLabel(labelId), { name })
      commit(mutations.EDIT_LABEL, { labelId, name })
    },
    async [actions.DELETE_LABEL]({ commit }, labelId) {
      await api.post(urls.deleteLabel(labelId))
      commit(mutations.REMOVE_LABEL, labelId)
    },
  },
  getters: {},
}
