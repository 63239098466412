<template>
  <incident-form
    @submit="createIncident"
    btn-text="Publish"
    header="New Incident"
  />
</template>

<script>
import Vue from 'vue'
import IncidentForm from '@/components/IncidentForm.vue'
import { actions } from '@/store/incidents'

export default Vue.extend({
  name: 'NewIncident',
  components: {
    IncidentForm,
  },
  methods: {
    async createIncident(incident) {
      try {
        const id = await this.$store.dispatch(actions.CREATE_INCIDENT, incident)
        this.$router.push({
          name: this.$routerUrls.incidentDetail,
          params: { id },
        })
      } catch (error) {
        console.log(error)
        this.$ui.showSnackbar(error.snackbar)
      }
    },
  },
})
</script>
