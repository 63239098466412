<template>
  <alerts-table />
</template>

<script>
import Vue from 'vue'
import AlertsTable from '@/components/AlertsTable.vue'

export default Vue.extend({
  name: 'Alerts',

  components: {
    AlertsTable,
  },
})
</script>
