import Vue from 'vue'
import Vuex from 'vuex'
import alertStore from '@/store/alerts'
import auth from '@/store/auth'
import incidentStore from '@/store/incidents'
import labelStore from '@/store/labels'
import serviceStore from '@/store/services'
import ui from '@/store/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alertStore,
    auth,
    incidentStore,
    labelStore,
    serviceStore,
    ui,
  },
})
