<template>
    <v-container fluid>
      <v-row>
        <v-col cols="5">
          <v-combobox
            v-model="currentGameId"
            :items="gameIds"
            label="Game ID"
            outlined
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="9">
          <v-card>
            <v-textarea
              name="Output JSON"
              filled
              label="Output JSON"
              auto-grow
              :value="currentFile"
              style="font-family:monospace"
            ></v-textarea>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import { urls } from '@/constants/api'

export default Vue.extend({
  name: "GameConfig",
  components: {
  },
  methods: {
    get_available_gameids: async function(){
      try {
        const payload = await api.get(urls.gameConfigIndex)
        this.gameIds = payload["data"];
      } catch(err) {
        console.log(err)
      }
    },
    get_jsonfile: async function(id){
      try {
        const payload = await api.get(urls.gameConfigDetails(id))
        this.currentFile = payload["data"];
      } catch(err) {
        console.log(err)
      }
    }
  },
  mounted() {
    this.get_available_gameids();
  },
  watch: {
    currentGameId: function() {
      if (this.currentGameId){
        this.get_jsonfile(this.currentGameId)
        this.currentFile = this.currentFile || "Select an option";
      }
    }
  },
  data: () => { return {
    currentGameId: "",
    gameIds: [""],
    currentFile: ""
  }}
})
</script>
