<template>
  <v-container class="ma-0">
    <alert-card :alert="alert" />
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import LabelCombobox from '@/components/LabelCombobox.vue'
import { actions } from '@/store/alerts'
import store from '@/store'
import AlertCard from '../components/AlertCard.vue'

export default Vue.extend({
  name: 'AlertDetail',
  components: {
    LabelCombobox,
    AlertCard,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      alert: (state) => state.alertStore.currentAlert,
    }),
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch(actions.FETCH_ALERT, to.params.id)
      .then(() => next((vm) => (vm.loading = false)))
  },
})
</script>
