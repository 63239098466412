<template>
  <incident-form
    :incident="incident"
    @submit="editIncident"
    btn-text="Edit"
    header="Edit Incident"
  />
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import IncidentForm from '@/components/IncidentForm.vue'
import { actions } from '@/store/incidents'
import store from '@/store'

export default Vue.extend({
  name: 'EditIncident',
  components: {
    IncidentForm,
  },
  computed: mapState({
    incident: (state) => state.incidentStore.currentIncident,
  }),
  beforeRouteEnter(to, from, next) {
    store
      .dispatch(actions.FETCH_INCIDENT, to.params.id)
      .then(() => next((vm) => (vm.loading = false)))
  },
  methods: {
    async editIncident(editIncident) {
      await this.$store.dispatch(actions.EDIT_INCIDENT, {
        incidentId: this.incident.id,
        editIncident,
      })
      this.$router.push({
        name: this.$routerUrls.incidentDetail,
        params: { id: this.incident.id },
      })
    },
  },
})
</script>
