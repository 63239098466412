import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import Ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {
  // included to fix https://github.com/vuetifyjs/vuetify/issues/12224
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#8bc34a',
        secondary: '#2196f3',
        accent: '#3f51b5',
        error: '#f44336',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#4caf50',
      },
    },
  },
})
