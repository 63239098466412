<template>
  <v-row>
    <v-col cols="7">
      <v-textarea
        v-model="form.description"
        hide-details
        flat
        filled
        auto-grow
        clearable
        rows="2"
        label="Leave a comment..."
      >
      </v-textarea>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="form.incident_status"
        :items="$enums.incidentStatus.array"
        item-text="text"
        item-value="value"
        label="Status"
        single-line
        filled
      ></v-select>
    </v-col>
    <v-col cols="1">
      <v-select
        v-model="form.is_public"
        :items="$enums.privacyOpts.array"
        item-value="value"
        dense
      >
        <template #[`selection`]="{ item }">
          <v-icon class="mr-2"> {{ item.icon }} </v-icon> {{ item.text }}
        </template>
        <template #[`item`]="{ item }">
          <v-icon class="mr-2" small> {{ item.icon }} </v-icon>{{ item.text }}
          <v-subheader> {{ item.description }} </v-subheader>
        </template>
      </v-select>
    </v-col>
    <v-col cols="2">
      <v-btn class="mx-0" depressed @click="submitForm"> Post </v-btn>
      <v-btn v-if="allowCancel" class="mt-2" depressed @click="cancelForm">
        Cancel
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
const defaultVals = () => ({
  description: null,
  incident_status: null,
  is_public: true,
})

export default {
  props: {
    incidentHistory: {
      type: Object,
      default: null,
    },
    allowCancel: Boolean,
  },
  data: () => ({
    form: null,
  }),
  created() {
    this.form = this.incidentHistory ? { ...this.incidentHistory } : defaultVals()
  },
  methods: {
    submitForm() {
      this.$emit('submit', this.form)
      this.form = defaultVals()
    },
    cancelForm() {
      this.$emit('cancel', this.form)
      this.form = defaultVals()
    },
  },
}
</script>
