import Vue from 'vue'
import api from '@/api'
import { urls } from '@/constants/api'

const mutations = {
  SET_INCIDENTS: 'set_incidents',
  SET_INCIDENT: 'set_incident',
  EDIT_INCIDENT: 'edit_incident',
  REMOVE_INCIDENT_TIME_RESOLVED: 'remove_incident_time_resolved',
  DELETE_INCIDENT: 'delete_incident',

  // Incident Upates
  SET_INCIDENT_HISTORIES: 'set_incident_histories',
  ADD_INCIDENT_HISTORY: 'add_incident_history',
  EDIT_INCIDENT_HISTORY: 'edit_incident_history',
  REMOVE_INCIDENT_HISTORY: 'remove_incident_history',
}

export const actions = {
  FETCH_INCIDENTS: 'fetch_incidents',
  FETCH_INCIDENT: 'fetch_incident',
  CREATE_INCIDENT: 'create_incident',
  EDIT_INCIDENT: 'edit_incident',
  DELETE_INCIDENT: 'delete_incident',
  RESOLVE_INCIDENT: 'resolve_incident',
  REOPEN_INCIDENT: 'reopen_incident',

  // Incident Updates
  FETCH_INCIDENT_HISTORIES: 'fetch_incident_histories',
  CREATE_INCIDENT_HISTORY: 'create_incident_history',
  EDIT_INCIDENT_HISTORY: 'edit_incident_history',
  DELETE_INCIDENT_HISTORY: 'delete_incident_history',
}

export default {
  state: {
    currentIncident: {},
    incidents: {
      meta: {},
      data: [],
    },
    currentIncidentHistories: {
      meta: {},
      data: [],
    },
  },
  mutations: {
    [mutations.SET_INCIDENTS](state, incidents) {
      state.incidents = incidents
    },
    [mutations.SET_INCIDENT](state, incident) {
      state.currentIncident = incident
    },
    [mutations.EDIT_INCIDENT](state, editIncident) {
      state.currentIncident = {
        ...state.currentIncident,
        ...editIncident,
      }
    },
    [mutations.REMOVE_INCIDENT_TIME_RESOLVED](state) {
      Vue.delete(state.currentIncident, 'time_resolved')
    },
    [mutations.DELETE_INCIDENT](state, incidentId) {
      state.currentIncident = {}
      const index = state.incidents.data.findIndex(
        (incident) => incident.id == incidentId
      )
      if (index !== -1) {
        state.incidents.data.splice(index, 1)
        state.incidents.meta.size = state.incidents.meta.size - 1
      }
    },

    // Incident Updates
    [mutations.SET_INCIDENT_HISTORIES](state, incidentHistories) {
      state.currentIncidentHistories = incidentHistories
    },
    [mutations.ADD_INCIDENT_HISTORY](state, { data, incidentHistory }) {
      // adds incidentHistory to beginning of array
      state.currentIncidentHistories.data.unshift({
        ...data,
        ...incidentHistory,
      })
    },
    [mutations.EDIT_INCIDENT_HISTORY](
      state,
      { incidentHistoryId, incidentHistory }
    ) {
      const index = state.currentIncidentHistories.data.findIndex(
        (history) => history.id == incidentHistoryId
      )
      const update = {
        ...state.currentIncidentHistories.data[index],
        ...incidentHistory,
      }
      Vue.set(state.currentIncidentHistories.data, index, update)
    },
    [mutations.REMOVE_INCIDENT_HISTORY](state, incidentHistoryId) {
      state.currentIncidentHistories.data =
        state.currentIncidentHistories.data.filter(
          (history) => history.id !== incidentHistoryId
        )
    },
  },
  actions: {
    async [actions.FETCH_INCIDENTS]({ commit }, params) {
      const payload = await api.get(urls.incidents, { params })
      commit(mutations.SET_INCIDENTS, payload)
    },
    async [actions.FETCH_INCIDENT]({ commit }, id) {
      const payload = await api.get(urls.incidentsDetail(id))
      commit(mutations.SET_INCIDENT, payload.data)
    },
    async [actions.CREATE_INCIDENT]({ commit }, incident) {
      const payload = await api.post(urls.createIncident, incident)
      commit(mutations.SET_INCIDENT, { ...payload.data, ...incident })
      return payload.data.id
    },
    async [actions.EDIT_INCIDENT]({ commit }, { incidentId, editIncident }) {
      const payload = await api.post(
        urls.editIncident(incidentId),
        editIncident
      )
      commit(mutations.EDIT_INCIDENT, editIncident)
    },
    async [actions.DELETE_INCIDENT]({ commit }, incidentId) {
      await api.post(urls.deleteIncident(incidentId))
      commit(mutations.DELETE_INCIDENT, incidentId)
    },
    async [actions.RESOLVE_INCIDENT]({ commit }, incidentId) {
      const payload = await api.post(urls.resolveIncident(incidentId))
      commit(mutations.EDIT_INCIDENT, payload.data)
    },
    async [actions.REOPEN_INCIDENT]({ commit }, incidentId) {
      await api.post(urls.reopenIncident(incidentId))
      commit(mutations.REMOVE_INCIDENT_TIME_RESOLVED)
    },

    // Incident Updates
    async [actions.FETCH_INCIDENT_HISTORIES]({ commit }, incidentId) {
      const payload = await api.get(urls.incidentHistories(incidentId))
      commit(mutations.SET_INCIDENT_HISTORIES, payload)
    },
    async [actions.CREATE_INCIDENT_HISTORY](
      { commit },
      { incidentId, incidentHistory }
    ) {
      const payload = await api.post(
        urls.createIncidentHistory(incidentId),
        incidentHistory
      )
      const data = payload.data
      commit(mutations.ADD_INCIDENT_HISTORY, { data, incidentHistory })
    },
    async [actions.EDIT_INCIDENT_HISTORY](
      { commit },
      { incidentId, incidentHistoryId, incidentHistory }
    ) {
      await api.post(
        urls.editIncidentHistory(incidentId, incidentHistoryId),
        incidentHistory
      )
      commit(mutations.EDIT_INCIDENT_HISTORY, {
        incidentHistoryId,
        incidentHistory,
      })
    },
    async [actions.DELETE_INCIDENT_HISTORY](
      { commit },
      { incidentId, incidentHistoryId }
    ) {
      await api.post(urls.deleteIncidentHistory(incidentId, incidentHistoryId))
      commit(mutations.REMOVE_INCIDENT_HISTORY, incidentHistoryId)
    },
  },
  getters: {},
}
