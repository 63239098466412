import api from '@/api'
import { urls } from '@/constants/api'

const mutations = {
  SET_SERVICES: 'set_services',
  EDIT_SERVICE: 'edit_service',
  REMOVE_SERVICE: 'remove_service',
}

export const actions = {
  FETCH_SERVICES: 'fetch_services',
  FETCH_SERVICES_AUTOCOMPLETE: 'fetch_services_autocomplete',
  EDIT_SERVICE: 'edit_service',
  DELETE_SERVICE: 'delete_service',
}

export default {
  state: () => ({
    services: {
      meta: {},
      data: [],
    },
  }),
  mutations: {
    [mutations.SET_SERVICES](state, services) {
      state.services = services
    },
    [mutations.EDIT_SERVICE](state, { serviceId, name }) {
      const service = state.services.data.find((s) => s.id === serviceId)
      Object.assign(service, { name })
    },
    [mutations.REMOVE_SERVICE](state, serviceId) {
      state.services.data = state.services.data.filter(
        (s) => s.id !== serviceId
      )
    },
  },
  actions: {
    async [actions.FETCH_SERVICES]({ commit }) {
      const payload = await api.get(urls.services)
      commit(mutations.SET_SERVICES, payload)
    },
    async [actions.FETCH_SERVICES_AUTOCOMPLETE]({ commit }, params) {
      const payload = await api.get(urls.servicesAutocomplete, { params })
      commit(mutations.SET_SERVICES, payload)
    },
    async [actions.EDIT_SERVICE]({ commit }, { serviceId, name }) {
      await api.post(urls.editService(serviceId), { name })
      commit(mutations.EDIT_SERVICE, { serviceId, name })
    },
    async [actions.DELETE_SERVICE]({ commit }, serviceId) {
      await api.post(urls.deleteService(serviceId))
      commit(mutations.REMOVE_SERVICE, serviceId)
    },
  },
  getters: {},
}
