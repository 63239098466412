<template>
  <v-chip class="ma-1" :color="$utils.getColor(label.id)">
    {{ label.name }}
  </v-chip>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      required: true,
    },
  },
}
</script>
