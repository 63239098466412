import Vue from 'vue'
import { mutations } from '@/store/ui'
import { errorKeys } from '@/constants/api'
import store from '@/store'

const Ui = {
  errorKeys,
  showSnackbar(content) {
    store.commit(mutations.SET_SNACKBAR, content)
  },
}

Ui.install = function (Vue) {
  Vue.prototype.$ui = Ui
}

Vue.use(Ui)

export default Ui
