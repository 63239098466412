<template>
  <v-app>
    <div v-if="$store.state.auth.isAuthenticated" app>
      <app-bar />
      <drawer />
    </div>

    <v-main>
      <router-view />
    </v-main>
    <snackbar />
  </v-app>
</template>

<script>
import Vue from 'vue'
import Snackbar from '@/components/Snackbar.vue'
import Drawer from '@/components/Drawer.vue'
import AppBar from '@/components/AppBar.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Snackbar,
    Drawer,
    AppBar,
  },
})
</script>
