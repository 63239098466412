<template>
  <v-combobox
    v-model="modelLabels"
    :items="items"
    :hint="!isEditing ? 'Click the icon to edit' : 'Click the icon to save'"
    :readonly="!isEditing"
    item-text="name"
    item-vaue="id"
    :search-input.sync="search"
    @update:search-input="updateSearch"
    outlined
    dense
    chips
    small-chips
    deletable-chips
    label="Labels"
    multiple
    return-object
    cache-items
    persistent-hint
    auto-select-first
    prepend-icon="mdi-label"
  >
    <template v-slot:append-outer>
      <v-slide-x-reverse-transition mode="out-in">
        <v-icon
          :key="`icon-${isEditing}`"
          :color="isEditing ? 'success' : 'info'"
          @click="saveLabels"
          v-text="isEditing ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
        ></v-icon>
      </v-slide-x-reverse-transition>
    </template>

    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ search }}</strong
            >". Press <kbd>tab</kbd> to create a new label
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { mapState } from 'vuex'
import { actions as labelActions } from '@/store/labels'
import { actions as alertActions } from '@/store/alerts'

export default {
  props: {
    alert: Object,
  },
  data: () => ({
    modelLabels: null,
    isEditing: false,
    search: null,
  }),
  beforeMount() {
    // create a copy to avoid mutating store directly
    this.modelLabels = [...this.alert.labels]
  },
  computed: {
    ...mapState({
      labels: (state) => state.labelStore.labels.data,
    }),
    items() {
      const res = []
      res.push(...this.labels)
      res.push(...this.alert.labels)
      return res
    },
    initialLabels() {
      return [...this.alert.labels]
    },
  },
  methods: {
    updateSearch(search) {
      if (search) this.retrieveLabels(search)
    },
    retrieveLabels(name) {
      const params = { name }
      this.$store.dispatch(labelActions.FETCH_LABELS_AUTOCOMPLETE, params)
    },
    saveLabels() {
      this.isEditing = !this.isEditing
      if (this.isEditing) return
      const original = this.initialLabels
      const edited = this.modelLabels
      const deleted = original.filter((label) => !edited.includes(label))
      const added = edited.filter((label) => !original.includes(label))
      for (const label of added) {
        this.addAlertLabel(label)
      }
      for (const label of deleted) {
        this.removeAlertLabel(label)
      }
    },
    addAlertLabel(label) {
      const alertId = this.alert.id
      if (typeof label === 'string')
        this.$store.dispatch(alertActions.CREATE_LABEL_AND_CREATE_ALERT_LABEL, {
          alertId,
          name: label,
        })
      else
        this.$store.dispatch(alertActions.CREATE_ALERT_LABEL, {
          alertId,
          label,
        })
    },
    removeAlertLabel(label) {
      const data = { label_id: label.id }
      const alertId = this.alert.id
      this.$store.dispatch(alertActions.DELETE_ALERT_LABEL, { alertId, data })
    },
  },
}
</script>
