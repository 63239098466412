<template>
  <incidents-table />
</template>

<script>
import Vue from 'vue'
import IncidentsTable from '@/components/IncidentsTable.vue'

export default Vue.extend({
  name: 'Alerts',

  components: {
    IncidentsTable,
  },
})
</script>
