<template>
  <v-container class="ma-2" fluid>
    <v-expansion-panels hover class="condensed mb-4">
      <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3"
          >Advanced Search</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="mt-4 mb-n6 ml-n4">
            <v-col cols="auto">
              <v-checkbox
                dense
                :false-value="$enums.boolean.enum.FALSE"
                :true-value="$enums.boolean.enum.TRUE"
                label="Open Only"
                v-model="filters.open_only"
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-autocomplete
                v-model="filters.servicesChosen"
                :items="services"
                :search-input.sync="filters.serviceSearch"
                @update:search-input="updateSearch"
                clearable
                @change="(e) => (filters.serviceSearch = null)"
                item-text="name"
                item-vaue="id"
                outlined
                dense
                chips
                small-chips
                multiple
                return-object
                cache-items
                auto-select-first
                hide-selected
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <datetime-picker label="Time From" v-model="filters.time_from" :datePickerProps='{max:new Date().toISOString().substr(0, 10)}'>
              </datetime-picker>
            </v-col>
            <v-col cols="auto">
              <datetime-picker label="Time To" v-model="filters.time_to" :datePickerProps='{max:new Date().toISOString().substr(0, 10)}'>
              </datetime-picker>
            </v-col>
            <v-col cols="auto"> </v-col>
            <v-col>
              <v-btn small color="primary" fab @click="retrieveFilteredAlerts"
                ><v-icon dark>mdi-magnify</v-icon></v-btn
              >
              <v-btn class="ml-2" small fab @click="clearFilters"
                ><v-icon dark>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 15, 20],
      }"
      :items="incidents"
      class="elevation-1"
      :item-class="(item) => 'pointer'"
      @click:row="openIncidentDetail"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Incidents</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn v-can="$perms.incidents.add" @click="openNewIncidentForm"
            >New Incident</v-btn
          >
        </v-toolbar>
      </template>

      <template #[`item.severity`]="{ item }">
        {{ $enums.severity.getObj(item.severity).text }}
      </template>
      <template #[`item.time_opened`]="{ item }">
        {{ $utils.timestampToHumanFormat(item.time_opened) }}
      </template>
      <template #[`item.time_resolved`]="{ item }">
        {{
          item.time_resolved
            ? $utils.timestampToHumanFormat(item.time_resolved)
            : '-'
        }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import DatetimePicker from '@/components/DatetimePicker.vue'
import { actions as incidentActions } from '@/store/incidents'
import { actions as serviceActions } from '@/store/services'

export default Vue.extend({
  name: 'IncidentsTable',
  components: {
    DatetimePicker,
  },
  data() {
    return {
      filters: {
        servicesChosen: [],
        serviceSearch: null,
        time_from: null,
        time_to: null,
        open_only: this.$enums.boolean.enum.FALSE,
      },
      headers: [
        { text: 'Incident ID', align: 'start', sortable: false, value: 'id' },
        { text: 'Title', sortable: false, value: 'title' },
        {
          text: 'Severity',
          value: 'severity',
          sortable: false,
        },
        {
          text: 'Time Opened',
          value: 'time_opened',
          sortable: false,
        },
        {
          text: 'Time Resolved',
          value: 'time_resolved',
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
      },

      loading: false,
    }
  },
  computed: mapState({
    incidents: (state) => state.incidentStore.incidents.data,
    totalItems: (state) => state.incidentStore.incidents.meta.size,
    services: (state) => state.serviceStore.services.data,
  }),
  watch: {
    options: {
      handler() {
        this.retrieveIncidents()
      },
      deep: true,
    },
  },
  methods: {
    retrieveIncidents() {
      this.loading = true
      const params = this.getRequestParams()
      this.$store
        .dispatch(incidentActions.FETCH_INCIDENTS, params)
        .then(() => (this.loading = false))
    },
    retrieveFilteredAlerts() {
      this.retrieveIncidents(1)
    },
    clearFilters() {
      this.filters = {
        servicesChosen: [],
        serviceSearch: null,
        time_from: null,
        time_to: null,
        open_only: this.$enums.boolean.enum.FALSE,
      }
      this.retrieveIncidents()
    },
    getRequestParams(page) {
      const { servicesChosen, time_from, time_to, open_only } = this.filters
      const params = {
        page: page || this.options.page,
        limit: this.options.itemsPerPage,
      }

      params.open_only = open_only

      if (servicesChosen.length) {
        params.service_ids = this.$utils.serializeIdArray(servicesChosen)
      }

      if (time_from) {
        params.time_from = this.$utils.dateToTimestamp(time_from)
      }

      if (time_to) {
        params.time_to = this.$utils.dateToTimestamp(time_to)
      }

      return params
    },
    retrieveServices(name) {
      const params = { name }
      this.$store.dispatch(serviceActions.FETCH_SERVICES_AUTOCOMPLETE, params)
    },
    updateSearch(search) {
      this.filters.serviceSearch = search
      if (search) this.retrieveServices(search)
    },
    openNewIncidentForm() {
      this.$router.push({ name: this.$routerUrls.newIncident })
    },
    openIncidentDetail(incident) {
      this.$router.push({
        name: this.$routerUrls.incidentDetail,
        params: { id: incident.id },
      })
    },
  },
})
</script>

<style>
.pointer {
  cursor: pointer;
}
.custom-ele {
  display: contents;
}
.v-expansion-panels.condensed .v-expansion-panel-header {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: auto;
}
</style>
