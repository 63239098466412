<template>
  <v-app-bar app extension-height="0" overflow fixed>
    <v-toolbar-title>{{ title }} </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AppBar',
  data: () => ({
    title: 'Home',
  }),

  watch: {
    $route(to, from) {
      this.title = to.name
    },
  },
})
</script>
