// see http://alumni.media.mit.edu/~wad/color/numbers.html for details
export default [
  'rgb(129, 38, 192)', // purple
  'rgb(129, 197, 122)', // light green
  'rgb(157, 175, 255)', // light blue
  'rgb(41, 208, 208)', // cyan
  'rgb(255, 146, 51)', // orange
  'rgb(233, 222, 187)', // tan
  'rgb(160, 160, 160)', // grey
]
