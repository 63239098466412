import Vue from 'vue'
import colors from '@/constants/colors'

const dateUtils = {
  timestampToDate(seconds) {
    return new Date(seconds * 1000)
  },
  dateToHumanFormat(date) {
    return date.toLocaleString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    })
  },
  timestampToHumanFormat(timestamp) {
    return this.dateToHumanFormat(this.timestampToDate(timestamp))
  },
  dateToTimestamp(date) {
    return date ? Math.round(date.getTime() / 1000) : null
  },
  millisecondsToHumanFormat(milliseconds) {
    function numberEnding(number) {
      return number > 1 ? 's' : ''
    }

    var temp = Math.floor(milliseconds / 1000)
    var years = Math.floor(temp / 31536000)
    if (years) {
      return years + ' year' + numberEnding(years)
    }
    var days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      return days + ' day' + numberEnding(days)
    }
    var hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return hours + ' hour' + numberEnding(hours)
    }
    var minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return minutes + ' minute' + numberEnding(minutes)
    }
    var seconds = temp % 60
    if (seconds) {
      return seconds + ' second' + numberEnding(seconds)
    }
    return 'just now'
  },
  timestampToDurationHumanFormat(timestamp) {
    const nowInMs = Date.now()
    const timestampInMs = timestamp * 1000
    const diffInMs = nowInMs - timestampInMs
    return this.millisecondsToHumanFormat(diffInMs)
  },
}

const serializeUtils = {
  serializeIdArray(items) {
    return items.map((item) => item.id).toString()
  },
}

const colorUtils = {
  getColor(id) {
    return colors[id % colors.length]
  },
}

const Utils = {
  ...dateUtils,
  ...serializeUtils,
  ...colorUtils,
}

Utils.install = function (Vue) {
  Vue.prototype.$utils = Utils
}

Vue.use(Utils)

export default Utils
