<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" md="6" xl="3">
        <v-card elevation="2" class="mx-auto my-12">
          <v-row>
            <v-col> <v-card-title>GOP Status</v-card-title> </v-col>
          </v-row>

          <v-card-text>
            <v-row justify="center" align="center">
              <v-col
                ><v-btn id="signinBtn" color="orange"
                  >Sign in with google</v-btn
                ></v-col
              >
            </v-row>

            <v-row align="center" class="mr-5 ml-5">
              <v-divider class="ma-2"></v-divider> Or
              <v-divider class="ma-2"></v-divider>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="username" label="Username" dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="password"
                  type="password"
                  label="Password"
                  dense
                  @keydown.enter="signInWithUsername"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="signInWithUsername">Sign in </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { CLIENT_ID } from '@/constants/auth'
import { actions } from '@/store/auth'

function getNested(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj)
}

export default Vue.extend({
  name: 'LoginForm',
  data: () => ({
    username: '',
    password: '',
  }),
  methods: {
    async signInWithUsername() {
      try {
        await this.$store.dispatch(actions.LOGIN, {
          username: this.username,
          password: this.password,
        })
        this.$router.push(
          this.$route.query.redirect || { name: this.$routerUrls.incidents }
        )
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    async onSignInSuccess(payload) {
      const access_token = payload.getAuthResponse().access_token
      try {
        await this.$store.dispatch(actions.GOOGLE_LOGIN, access_token)
        this.$router.push(
          this.$route.query.redirect || { name: this.$routerUrls.incidents }
        )
      } catch (error) {
        this.$ui.showSnackbar(error.snackbar)
      }
    },
    onSignInError(error) {
      this.$ui.showSnackbar(this.$ui.errorKeys.error_invalid_login.snackbar)
    },
  },
  mounted() {
    window.gapi.load('auth2', async () => {
      const auth2 = await window.gapi.auth2.init({
        client_id: CLIENT_ID,
        scope: 'profile',
      })
      auth2.attachClickHandler(
        'signinBtn',
        {},
        this.onSignInSuccess,
        this.onSignInError
      )
    })
  },
})
</script>
