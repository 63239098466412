import Vue from 'vue'
import api from '@/api'
import { urls } from '@/constants/api'

const mutations = {
  SET_ALERTS: 'set_alerts',
  SET_ALERT: 'set_alert',
  EDIT_ALERT: 'edit_alert',
  REMOVE_ALERT_TIME_RESOLVED: 'remove_alert_time_resolved',
  ADD_ALERT_LABEL: 'add_alert_label',
  DELETE_ALERT_LABEL: 'delete_alert_label',
}

export const actions = {
  FETCH_ALERTS: 'alerts_retrieve',
  FETCH_ALERT: 'fetch_alert',
  RESOLVE_ALERTS: 'resolve_alert',
  REOPEN_ALERTS: 'reopen_alert',
  CREATE_ALERT_LABEL: 'alert_label_create',
  CREATE_LABEL_AND_CREATE_ALERT_LABEL: 'create_label_and_create_alert_label',
  DELETE_ALERT_LABEL: 'alert_label_delete',
  RESOLVE_ALERTS_BULK: 'resolve_alert_bulk',
  REOPEN_ALERTS_BULK: 'reopen_alert_bulk'
}

export default {
  state: () => ({
    currentAlert: {},
    alerts: {
      meta: {},
      data: [],
    },
  }),
  mutations: {
    [mutations.SET_ALERTS](state, alerts) {
      state.alerts = alerts
    },
    [mutations.SET_ALERT](state, alert) {
      state.currentAlert = alert
    },
    [mutations.EDIT_ALERT](state, { alertId, editAlert }) {
      state.currentAlert = {
        ...state.currentAlert,
        ...editAlert,
      }
      const index = state.alerts.data.findIndex((alert) => alert.id == alertId)
      const alert = {
        ...state.alerts.data[index],
        ...editAlert,
      }
      Vue.set(state.alerts.data, index, alert)
    },
    [mutations.REMOVE_ALERT_TIME_RESOLVED](state, alertId) {
      if (state.currentAlert != null && state.currentAlert.time_resolved != null) {
        Vue.delete(state.currentAlert, 'time_resolved')
      }
      const index = state.alerts.data.findIndex((alert) => alert.id == alertId)
      const alert = state.alerts.data[index]
      if (alert && alert.time_resolved != null) {
        Vue.delete(alert, 'time_resolved')
      }
      Vue.set(state.alerts.data, index, alert)    
    },
    [mutations.ADD_ALERT_LABEL](state, { alertId, label }) {
      const alert = state.alerts.data.find((alert) => alert.id == alertId)
      console.log(alert)

      if (alert) {
        // alert is loaded
        alert.labels.push(label)
        console.log(alert)
      }

      if (state.currentAlert.labels)
        // currentAlert is loaded
        state.currentAlert.labels.push(label)
    },
    [mutations.DELETE_ALERT_LABEL](state, { alertId, labelId }) {
      const alert = state.alerts.data.find((alert) => alert.id == alertId)
      if (alert)
        // alert is loaded
        alert.labels = alert.labels.filter((label) => label.id !== labelId)

      if (state.currentAlert.labels)
        // currentAlert is loaded
        state.currentAlert.labels = state.currentAlert.labels.filter(
          (label) => label.id !== labelId
        )
    },
  },
  actions: {
    async [actions.FETCH_ALERTS]({ commit }, params) {
      const payload = await api.get(urls.alerts, { params })
      commit(mutations.SET_ALERTS, payload)
    },
    async [actions.FETCH_ALERT]({ commit }, id) {
      const payload = await api.get(urls.alertsDetail(id))
      commit(mutations.SET_ALERT, payload.data)
    },
    async [actions.RESOLVE_ALERTS]({ commit }, alertIds) {
      const payload = await api.post(urls.resolveAlerts, {
        alert_ids: alertIds,
      })
      const time_resolved = payload.data.time_resolved
      alertIds.forEach((alertId) =>
        commit(mutations.EDIT_ALERT, {
          alertId,
          editAlert: { time_resolved },
        })
      )
    },
    async [actions.REOPEN_ALERTS]({ commit }, alertIds) {
      await api.post(urls.reopenAlerts, { alert_ids: alertIds })
      alertIds.forEach((id) => commit(mutations.REMOVE_ALERT_TIME_RESOLVED, id))
    },
    async [actions.RESOLVE_ALERTS_BULK]({ commit }, params) {
      const payload = await api.post(urls.resolveAlertsBulk, params)
      const alertIds = payload.data.ids
      const time_resolved = payload.data.time_resolved
      alertIds.forEach((alertId) =>
        commit(mutations.EDIT_ALERT, {
          alertId,
          editAlert: { time_resolved },
        })
      )
    },
    async [actions.REOPEN_ALERTS_BULK]({ commit }, params) {
      const payload = await api.post(urls.reopenAlertsBulk, params)
      const alertIds = payload.data.ids
      console.log(alertIds)
      alertIds.forEach((id) => commit(mutations.REMOVE_ALERT_TIME_RESOLVED, id))
    },

    // Alert Labels
    async [actions.CREATE_ALERT_LABEL]({ commit }, { alertId, label }) {
      await api.post(urls.alertsCreateLabel(alertId), {
        label_id: label.id,
      })
      commit(mutations.ADD_ALERT_LABEL, { alertId, label })
    },
    async [actions.CREATE_LABEL_AND_CREATE_ALERT_LABEL](
      { commit },
      { alertId, name }
    ) {
      const payload = await api.post(urls.alertsAddLabelAndCreate(alertId), {
        name,
      })
      const label = {
        id: payload.data.id,
        name,
      }
      commit(mutations.ADD_ALERT_LABEL, { alertId, label })
    },
    async [actions.DELETE_ALERT_LABEL]({ commit }, { alertId, data }) {
      await api.post(urls.deleteAlertLabel(alertId), data)
      commit(mutations.DELETE_ALERT_LABEL, { alertId, labelId: data.label_id })
    },
  },
  getters: {},
}
