<template>
  <v-snackbar
    v-model="show"
    :color="snackbar.color"
    :timeout="snackbar.timeout || 2000"
    bottom
  >
    {{ snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="show = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
import { mutations } from '@/store/ui'

export default {
  computed: mapState({
    snackbar: (state) => state.ui.snackbar,
  }),
  watch: {
    snackbar(newVal) {
      this.show = true
    },
  },

  data() {
    return {
      show: false,
    }
  },
}
</script>
